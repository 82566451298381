// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {FacilityAccessibilityProduct} from "../product.facility/model/FacilityAccessibilityProduct";
import {EProductType} from "../javascript.lib.mojo-base/product.common/ProductType";
import {FacilityAccessibilityProduct2} from "../product.facility/model/FacilityAccessibilityProduct2";
import {FacilityAccessibilityProduct3} from "../product.facility/model/FacilityAccessibilityProduct3";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  filename: 'environment.test-facilities-495353.ts',
  isDevelopment: false,
  isProduction: true,
  isTest: false,


  firebaseConfig: {
    apiKey: "AIzaSyB8CCM8GK-BkOJCENBxhkQ4xAyI9aGqKJk",
    appId: "1:720763136710:web:6e460181e656a4d62085c7",
    authDomain: "test-facilities-495353.firebaseapp.com",
    databaseURL: "https://test-facilities-495353-default-rtdb.europe-west1.firebasedatabase.app",
    messagingSenderId: "720763136710",
    projectId: "test-facilities-495353",
    storageBucket: "test-facilities-495353.appspot.com",
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-test-facilities-495353.cloudfunctions.net',

  productConfig: {

    firebaseAnswersRoot: '/answers-x/facilities',
    productType: EProductType.facilities,
  },

  // product:  ProductHelper.cloneProduct( new FacilityAccessibilityProduct(), true )
  // product:  FacilityAccessibilityProduct3.INSTANCE
  product:  FacilityAccessibilityProduct4.INSTANCE,

  supportsSubmitButton: true,


};
