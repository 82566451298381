


export class BrowserAppEvaluationToolInfo {

  public version = '2023.7.6.1';

  constructor() {
  }

}
