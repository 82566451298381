import {BaseReference} from "../../model/BaseReference";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";


export interface INocoAttachment {

  mimetype: string;
  size: number;
  title: string;
  url: string;

}

export class NocoAttachment extends BaseReference<INocoAttachment> {

  private static _log: ILogger = LoggerFactory.build( 'NocoAttachment' );

  public isJpeg: boolean = false;
  public isPng: boolean = false;
  public isSvg: boolean = false;

  public static build( attachments: INocoAttachment[], nocoDbId: number ): NocoAttachment[] {

    const answer: NocoAttachment[] = [];

    for( const attachmentValue of attachments ) {

      const attachment = new NocoAttachment( attachmentValue, nocoDbId );
      if( attachment.isJpeg || attachment.isPng || attachment.isSvg  ) {

        answer.push( attachment );
      } else {

        NocoAttachment._log.warn( 'unhandled type', 'attachmentValue.mimetype', attachmentValue.mimetype, 'nocoDbId', nocoDbId );
      }
    }

    return answer;
  }


  public toFilename( index: number = null ): string|null {

    let fileExtension = null;

    if( this.isJpeg ) {

      fileExtension = 'jpg';
    } else if( this.isPng ) {

      fileExtension = 'png';
    } else if( this.isSvg ) {

      fileExtension = 'svg';
    }

    if( !fileExtension ) {

      return null;
    }

    if( 0 === index || index ) {

      return `${this.nocoDbId}.${index}.${fileExtension}`;
    }
    return `${this.nocoDbId}.${fileExtension}`;

  }

  protected onSetValue(value: INocoAttachment | null,  ) {

    if( !value ) {
      return;
    }

    if( 'image/jpeg' === value.mimetype ) {

      this.isJpeg = true;
    } else if( 'image/png' === value.mimetype ) {

      this.isPng = true;
    } else if( 'image/svg+xml' === value.mimetype ) {

      this.isSvg = true;
    }

  }

  constructor( value: INocoAttachment | null,
               public nocoDbId: number ) {

    super( value );

    if ( value ) {
      this.value = value;
    }
  }




}
